export default {
  title: "Bienvenue à nouveau ! ",
  "type-select": "Vous êtes...",
  "having-trouble": "Mot de passe oublié ?",
  "keep-me-signed-in": "Je souhaite rester connecté",
  "dont-have-an-account": "Pas de compte ? ",
  type: {
    householder: "Propriétaire",
    installer: "Professionnel",
  },
  "admin-alert":
    "Ne renseignez jamais votre mot de passe ailleurs que sur projetsolaire.com. Nous ne vous demanderons jamais de communiquer votre mot de passe ailleurs que lors de votre connexion. ",
  errors: {
    "account-locked":
      "Compte temporairement verrouillé. Veuillez réinitialiser votre mot de passe ou contacter le gestionnaire du compte.",
  },
}
